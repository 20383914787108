<template>
  <div>
    <b-card title="Settle Invoice" class="settle-invoice-card">
      <h5>
        Invoice
        <span class="invoice-number">#{{ paymentInvoiceId }}</span>
      </h5>
    </b-card>
    <b-row>
      <b-col lg="5" md="6" sm="12">
        <b-card>
          <h5>Payment Instructions</h5>
          <ol>
            <li>Enter the cheque details below and submit.</li>
            <li>Once the payment has been approved by our team, you will be able to download yout license(s).</li>
          </ol>
          <b-form-group label="Cheque Number">
            <b-form-input
              id="enterChequeNumber"
              placeholder="Enter the cheque number"
              v-model="chequeNumber"
            />
          </b-form-group>
          <b-form-group label="Bank Code">
            <b-form-input
              id="bankCode"
              placeholder="Enter the bank code"
              v-model="bankCode"
            />
          </b-form-group>
          <b-form-group label="Account Number">
            <b-form-input
              id="enterAccountNumber"
              placeholder="Enter the account number"
              v-model="accountNumber"
            />
          </b-form-group>
          <div>
            <b-button variant="outline-secondary" :to="{name: 'invoices'}">
              Cancel
            </b-button>
            <b-button
              class="float-right"
              variant="primary"
              @click="saveChequeDetails"
              :disabled="isLoading"
              >Submit
              <b-spinner v-if="isLoading" small />
              </b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BFormInput,
  BFormGroup,
  BSpinner,
} from "bootstrap-vue";
import invoiceStoreModule from "./invoiceStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import useInvoiceList from "./useInvoiceList";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BFormInput,
    BFormGroup,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
    required,
  },
  setup() {
    const toast = useToast();
    const invoiceData = ref(null);
    const paymentDetails = ref({});
    const totalAmount = ref(0);

    const INVOICE_APP_STORE_MODULE_NAME = "invoice";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("invoice/fetchInvoice", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        paymentInvoiceId.value = router.currentRoute.params.id
        invoiceData.value = response.data.data;
        invoiceData.value.licenses.forEach((license) => {
          totalAmount.value += license.tariff.price;
        });
      })
      .catch((error) => {
        if (error.response.status === 404) {
          invoiceData.value = undefined;
        }
      });

    const {
      isLoading,

      refEnterPhoneNumberForm,
      paymentInvoiceId,

      chequeNumber,
      bankCode,
      accountNumber,
      saveChequeDetails,
    } = useInvoiceList();

    return {
      isLoading,

      invoiceData,
      paymentDetails,
      totalAmount,

      refEnterPhoneNumberForm,
      paymentInvoiceId,

      chequeNumber,
      bankCode,
      accountNumber,
      saveChequeDetails,
    };
  },
};
</script>
<style scoped>
  .settle-invoice-card {
    width: 50%
  }
  .instructions-card {
    width: 75%
  }
  @media only screen and (max-width: 992px) {
    .settle-invoice-card {
      width: 100%;
    }
    .instructions-card {
      width: 100%;
    }
  }
</style>